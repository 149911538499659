import PlanningDay from "../utils/PlanningDay";
import PlanningWeek from "../utils/PlanningWeek";

export default class PlanningIndex {

    constructor() {

        this.business_hours = $("#calendar_week").data("working_hours_schedule");

        this.calendar_day = new PlanningDay();
        this.calendar_week = new PlanningWeek();
        this.bindEvents();
        window.application.setOnDataChangeListener(this);
    }

    bindEvents() {
        this.rebind();
        
    }

    rebind() {
        console.log("rebind v1");
        $(".open-student-detail-modal-from-planning").click(function(e) {
            e.preventDefault();
            console.log("Open student modal");

            var targetModal = $(this).data("target");
            $("#planning-modal").modal("hide");

            $("#student_modal_container").html("");
            $(targetModal).appendTo("#student_modal_container");
            $(targetModal).modal();
        }); 

        $(".close-student-detail").click(function(e) {
            e.preventDefault();

            var targetModal = $(this).data("target");
            $(targetModal).modal("hide");

            $("#planning-modal").modal();

        }); 

        $(".close-all-modal").click(function(e) {
            e.preventDefault();

            $(".modal").modal("hide");

            //$("#planning-modal").modal("hide");

        }); 
    }

    onDataChanged(data) {
        this.calendar_week.onDataChanged(data);
    }

    onDestroy() {
    }
}