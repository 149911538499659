import { Calendar } from '@fullcalendar/core';
import frenchTranslation from '@fullcalendar/core/locales/fr';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';

export default class Planning {

    constructor() {
        this.customer_id = null;
        this.calendar = null;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    bindEvents() {
        let self = this;

        $(".js-load-modal").click(this.eventClick);

        $('a[href="#calendar_view"]').on("shown.bs.tab", function (){
            self.calendar.render();
        })
        $('#fc_next').click(this.prev_month);
        $('#fc_prev').click(this.next_month);


        $('#planning_next').click(this.planning_prev_month);
        $('#planning_prev').click(this.planning_next_month);
    }

    onDataChanged(data) {
        if(data.customer_id !== undefined){
            this.start_date = new Date(data.start_date*1000);
            this.date = Date.startOfMonth(this.start_date);
            this.customer_id = data.customer_id;
            this.initCalendar();
            this.initPlanning();
        }
    }

    onDestroy() {
    }

    initCalendar() {
        this.calendar = new Calendar(document.getElementById('calendar'), {
            plugins: [ dayGridPlugin, bootstrapPlugin ],
            themeSystem: 'bootstrap',
            initialView: 'dayGridMonth',
            headerToolbar: {
                start: '',
                center: '',
                end: ''
            },
            initialDate: this.start_date,
            locales: [ frenchTranslation ],
            locale: I18n.locale,
            timeZone: 'UTC',
            aspectRatio: (1/(150/$("#calendar_view").width())),
            events: {
                url: '/planning_data/'+this.customer_id,
                method: 'GET',
                failure: function() {
                    console.log('there was an error while fetching events!');
                }
            },
            eventContent: function(event, element) {
                return {html: event.event.title}
            },
            eventSourceSuccess: function(content, xhr) {
                console.log("eventSourceSuccess -> "+content.events.length);
                return content.events;
            },
            eventClick: this.fcEventClick
        });
    }

    prev_month(e){
        let self = application.current_handler.planning;
        e.preventDefault();
        self.calendar.next();
        self.updateTextDate();
    }

    next_month(e){
        let self = application.current_handler.planning;
        e.preventDefault();
        self.calendar.prev();
        self.updateTextDate();
    }

    updateTextDate(){
        $("#fc_date").text(I18n.l("date.formats.month_year", this.calendar.getDate()));
    }

    fcEventClick(info){
        let self = application.current_handler.planning
        let dateObj = new Date(info.event.start.toISOString());
        self.loadModal(self.format_date(dateObj));
    }

    eventClick(e){
        e.preventDefault();
        let self = application.current_handler.planning
        self.loadModal($(this).data("date"))
    }

    loadModal(date){

        let ajaxData = {
            url: '/planning_data/'+this.customer_id+'/get_modal_content',
            data : {day: date},
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#planning_modal_content').empty().append(html);
            $('#planning-modal').modal('show');
        });
    }

    initPlanning() {
        let self = this;
        let ajaxData = {
            url: '/planning_data/'+this.customer_id+'/planning_view',
            data : {date: this.date},
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#planning_view_items').empty().append(html);
            $(".js-load-modal").unbind("click", self.eventClick).click(self.eventClick);
        });
    }

    format_date(dateObj){
        let month = dateObj.getMonth() + 1;
        let day = dateObj.getDate();
        let year = dateObj.getFullYear();
        return year + "-" + month + "-" + day;
    }

    planning_prev_month(e) {
        let self = application.current_handler.planning;
        e.preventDefault();
        if (self.date.getMonth() === 0){
            self.date = new Date(self.date.getFullYear()-1, 11, 1)
        }else{
            self.date = new Date(self.date.getFullYear(), self.date.getMonth()-1, 1)
        }
        self.updatePlanningTextDate();
    }

    planning_next_month(e) {
        let self = application.current_handler.planning;
        e.preventDefault();
        if (self.date.getMonth() === 11){
            self.date = new Date(self.date.getFullYear()+1, 0, 1)
        }else{
            self.date = new Date(self.date.getFullYear(), self.date.getMonth()+1, 1)
        }
        self.updatePlanningTextDate();
    }

    updatePlanningTextDate(){
        $("#planning_date").text(I18n.l("date.formats.month_year", this.date));
        this.initPlanning()
    }
}